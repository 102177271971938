<!-- eslint-disable vue/no-unused-components -->
<!-- eslint-disable vue/no-unused-components -->
<template>
  <link
    href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css"
    rel="stylesheet"
  />
  <link href="https://www.tamandayu.com/css/style.css" rel="stylesheet" />

  <link rel="stylesheet" href="https://www.tamandayu.com/css/animate.css" />
  <!-- <link rel="stylesheet" href="https://www.tamandayu.com/css/bootsnav.css" /> -->
  <link rel="stylesheet" href="https://www.tamandayu.com/css/responsive.css" />
  <link
    rel="stylesheet"
    href="https://www.tamandayu.com/css/owl.theme.default.min.css"
  />
  <link
    rel="stylesheet"
    href="https://www.tamandayu.com/css/magnific-popup.css"
  />
  <link
    rel="stylesheet"
    href="https://www.tamandayu.com/css/owl.carousel.min.css"
  />

  <top-bar-v-3 v-if="topBarV3" />
  <top-bar-v-2 v-else />

  <side-bar />
  <router-view> </router-view>
  <!-- <footer-component v-if="store.configurations"></footer-component> -->
  <FooterComponent />
  <popup-button />
  <cookie-consent />
</template>

<style scoped>
/* @import "../../assets/template/style.css"; */
/* @import "../../assets/template/css/animate.css"; */
/* @import "../../assets/template/css/responsive.css"; */
@import "../../assets/template/css/bootsnav.css";
/* @import "../../assets/template/css/owl.theme.default.min.css"; */
/* @import "../../assets/template/css/magnific-popup.css"; */
/* @import "../../assets/template/css/owl.carousel.min.css"; */
</style>

<script>
import "bootstrap/dist/css/bootstrap.css";
import TopBarV3 from "./top_bar_v3.vue";
import TopBarV2 from "./top_bar_v2.vue";
import SideBar from "../components/SideBar.vue";
import FooterComponent from "./footer_component_v2.vue";
import WhatsappComponent from "./whatsapp_component.vue";
import { configurationStore } from "../stores/configurationStore";
import PopupButton from "./popup_button_v2.vue";
import cookieConsent from "./cookie_consent.vue";

export default {
  setup() {
    const store = configurationStore();
    return {
      store,
    };
  },
  components: {
    TopBarV3,
    TopBarV2,
    SideBar,
    FooterComponent,
    // eslint-disable-next-line vue/no-unused-components
    WhatsappComponent,
    PopupButton,
    cookieConsent,
  },
  data() {
    return {
      topBarV3: false,
    };
  },
  watch: {
    $route() {
      this.isPostDetailPage();
    },
  },
  mounted() {
    this.store.getConfiguration();
    this.isPostDetailPage();
  },
  methods: {
    isPostDetailPage() {
      this.topBarV3 = false;
      if (this.$route.params.path) {
        console.log(this.$route.params.path);
        this.topBarV3 = true;
      }
    },
  },
};
</script>
