<template>
  <div class="whatsapp-container">
    <button class="whatsapp-icon" @click="toggleWhatsapp">
      <span class="fab fa-whatsapp" style="font-size: 24px"></span>
    </button>
    <transition name="fade">
      <div class="whatsapp-dropdown" v-if="showWhatsapp">
        <div
          class="whatsapp-number"
          v-for="(item, index) in contacts"
          :key="index"
        >
          <!-- <span class="label">Sales:</span> -->
          <a :href="item.target" target="_blank">{{ item.label }} </a>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showWhatsapp: false,
      contacts: [
        {
          label: "Jack Terrace Resto",
          target:
            "https://api.whatsapp.com/send?phone=628113530234&text=Mau%20order%20di%20Jack%20Terrace%20Resto,%20nih..",
        },
        {
          label: "Sky Terrace Resto",
          target:
            "https://api.whatsapp.com/send?phone=6281130565234&text=Mau%20reservasi%20di%20Sky%20Terrace%20resto%20sushi,%20nih..",
        },
        {
          label: "GOLF Registration",
          target:
            "https://api.whatsapp.com/send?phone=628113635234&text=Mau%20registrasi%20Golf%20dunk..",
        },
        {
          label: "Driving Range Reservation",
          target:
            "https://api.whatsapp.com/send?phone=6281132290234&text=Mau%20reservasi%20di%20Driving%20Range%20Golf%20ya..",
        },
        {
          label: "Hotel Reservation",
          target:
            "https://api.whatsapp.com/send?phone=628113080687&text=Mau%20reservasi%20Hotel%20ya..",
        },
        {
          label: "Sales Team Inquiry",
          target:
            "https://api.whatsapp.com/send?phone=628113503121&text=Dear%20Sales%20Team,%0D%0AMohon%20penawarannya%20untuk%20Meeting%20ya..",
        },
      ],
    };
  },
  methods: {
    toggleWhatsapp() {
      this.showWhatsapp = !this.showWhatsapp;
    },
  },
};
</script>

<style>
.whatsapp-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999;
}

.whatsapp-icon {
  width: 60px;
  height: 60px;
  background-color: #25d366;
  color: white;
  font-size: 18px;
  border-radius: 50%;
  border: 0px;
}

.whatsapp-dropdown {
  position: absolute;
  top: -242px;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 200px;
  border-radius: 15px;
}

.whatsapp-number {
  margin-bottom: 10px;
}

.label {
  font-weight: bold;
  margin-right: 5px;
}
</style>
