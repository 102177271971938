import { defineStore } from 'pinia';
import { getListCategory } from "@/common/datasource";

export const sideBarStore = defineStore('sideBar', {
    state: () => {
        return {
            name: 'sideBar',
            collapsed: true,
            menus: [
                {
                    name: "Home",
                    href: "/",
                },
                {
                    name: "About Taman Dayu",
                    categoryId: 5,
                    children: []
                },
                {
                    name: "Taman Dayu Golf",
                    categoryId: 6,
                    children: []
                },
                {
                    name: "Amenities",
                    categoryId: 4,
                    children: []
                },
                {
                    name: "The Resorts",
                    categoryId: 3,
                    children: []
                },
                {
                    name: "Meetings & Celebrations",
                    categoryId: 7,
                    children: []
                },
                {
                    name: "2023 RATES",
                    categoryId: 8,
                    children: []
                },
                {
                    name: "News & Events",
                    href: '/category/event-news',
                },
                {
                    name: "Promotions",
                    href: '/category/promotions',
                },

            ]
        }
    },
    actions: {
        buttonCollapse() {
            this.collapsed = !this.collapsed;
        },

        async getCategories() {
            const categories = await getListCategory();
            this.menus = [
                {
                    name: "Home",
                    href: "/",
                }
            ];
            categories.forEach(category => {
                const posts = [];
                const categoryName = category.name.replaceAll(' ', '-').toLowerCase();
                category.posts.forEach(post => {
                    if (post.isActive === 1) {
                        posts.push({ name: post.title, href: `/${categoryName}/${post.path}` })
                    }
                });

                this.menus.push({
                    name: category.name,
                    categoryId: category.id,
                    ...(![1,2].includes(category.id) && { children: posts }),
                    ...((category.id === 1 || category.id === 2) && { href: `/category/${categoryName}` })
                });
            });

            // this.menus.push({
            //     name: "360 Tour (Coming Soon)",
            //     href: "/under-construction",
            // },);
            // this.menus.push({
            //     name: "Catalogs",
            //     href: "/catalogs",
            // });
        },
    },
})