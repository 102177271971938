import { defineStore } from 'pinia';
import { getPostByPath } from "@/common/datasource";

export const postStore = defineStore('post', {
    state: () => {
        return {
            name: 'post',
            post: null
        }
    },
    actions: {
        async getPostByPath(path) {
            this.post = await getPostByPath(path);
            return this.post;
        },
    },
})