<template>
  <div>
    <div class="blog-area single full-blog default-padding">
      <div class="container container-blog">
        <div class="row">
          <div class="col-12 d-flex justify-content-center">
            <h1 class="post-title">{{ post.title }}</h1>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <golf-course-maps v-if="post.path === 'golf-course-map'" />

            <div class="d-flex justify-content-center" v-if="post.path == 'our-location'">
              <iframe
                width="736"
                height="337"
                style="border: 1"
                allowfullscreen="yes"
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3953.814928449841!2d112.6751895!3d-7.7030001!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd7d98da8d8e00b%3A0xa16e244b9699f84f!2sTaman%20Dayu%20Golf%20Club%20%26%20Resort!5e0!3m2!1sen!2sid!4v1658378008463!5m2!1sen!2sid"
              ></iframe>
            </div>

            <div v-if="post.categoryId === 2">
              <div class="row">
                <div class="col-md-8">
                  <div v-html="post.content" class="content"></div>
                </div>
                <div class="col-md-4">
                  <div class="row">
                    <div class="col-12 text-center">
                      <h3 style="text-decoration: underline">
                        More Information
                      </h3>
                    </div>
                  </div>

                  <div
                    class="row mt-3"
                    style="font-size: 18px"
                    v-for="(info, index) in moreInformations"
                    :key="index"
                  >
                    <div class="col-12 text-center">
                      {{ info.label }} <br />
                      {{ store.findByKey(info.key) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="blog-items content" v-html="post.content"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import { getPostByPath } from "../../common/datasource.js";
import { configurationStore } from "../stores/configurationStore";
// eslint-disable-next-line no-unused-vars
import { postStore } from "../stores/postStore";
import constants from "../../common/constants";
import GolfCourseMaps from "./customPost/golf_course_maps";

export default {
  components: { GolfCourseMaps },
  watch: {
    $route() {
      this.getPost();
    },
  },
  setup() {
    const store = configurationStore();
    return {
      store,
    };
  },
  data() {
    return {
      post: {},
      constants: constants,
      moreInformations: [
        {
          label: "Phone",
          key: "PHONE_NUMBER",
        },
        {
          label: "Phone Hunting",
          key: "PHONE_NUMBER_2",
        },
        {
          label: "Fax",
          key: "FAX",
        },
        {
          label: "Email",
          key: "EMAIL",
        },
        {
          label: "Location",
          key: "ADDRESS",
        },
      ],
    };
  },
  mounted() {
    this.getPost();
  },

  methods: {
    async getPost() {
      this.post = await postStore().getPostByPath(this.$route.params.path);
    },
  },
};
</script>

<style lang="scss">
.ql-align-right {
  text-align: right;
}
.ql-align-center {
  text-align: center;
}
.ql-align-left {
  text-align: left;
}
// .post-title {
//   font-family: "Oswald";
//   font-size: 26px;
// }

.blog-items {
  font-family: "Roboto" !important;
  font-size: 18px;
}

.container-blog {
    margin-top: 27vh;
  }

@media (max-width: 400px) {
  // .container-blog {
  //   margin-top: 50%;
  // }

  .ql-video {
    width: 100% !important;
  }
}

@media (min-width: 400px) {
  // .container-blog {
  //   margin-top: 10%;
  // }
}

@media (min-width: 400px) {
  .ql-video {
    margin-left: 25%;
  }
}
</style>
