<template>
  <div>
    <div class="pagetitle">
      <h1>Configuration</h1>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body pt-4">
            <Form @submit="onSave">
              <div
                class="form-group row"
                v-for="(data, index) in listData"
                :key="index"
              >
                <label for="inputPassword" class="col-sm-2 col-form-label">{{
                  data.label
                }}</label>
                <div class="col-sm-10">
                  <Field
                    type="text"
                    class="form-control"
                    v-model="data.value"
                    :placeholder="data.label"
                    :name="data.key"
                    :rules="requiredValidator"
                  />
                  <ErrorMessage :name="data.key" style="color: red" />
                </div>
              </div>
              <div class="d-flex justify-content-end">
                <button
                  type="submit"
                  class="btn btn-sm btn-info"
                  :disabled="isSaveLoading"
                >
                  <span
                    v-if="isSaveLoading"
                    class="spinner-border spinner-border-sm"
                  ></span>
                  <span> <span class="fa fa-save"></span> Save </span>
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getListConfiguration, patchBulkConfiguration } from "@/common/datasource";
import * as Yup from "yup";

const requiredValidator = Yup.string().required();

export default {
  data() {
    return {
      isSaveLoading: false,
      showModal: false,
      isLoadingData: false,
      listData: [],
      requiredValidator: requiredValidator,
    };
  },
  mounted() {
    this.getListData();
  },
  methods: {
    async getListData() {
      this.isLoadingData = true;
      this.listData = await getListConfiguration();
      this.isLoadingData = false;
    },

    buildPayload(){
      const payload = [];

      this.listData.forEach(data => {
        payload.push({
          id: data.id,
          value: data.value
        });
      });

      return payload;
    },

    async onSave() {
      this.isSaveLoading = true;
      await patchBulkConfiguration(this.buildPayload());
      this.$toast.success('Data saved successfully');
      this.isSaveLoading = false;

      await this.getListData();
    },
  },
};
</script>
