<template>
  <div>
    <header id="home">
      <!-- Start Navigation -->
      <div class="top-hitam-gradasi">
        <div class="butmenu">
          <span style="cursor: pointer" @click="store.buttonCollapse"
            ><i class="fa fa-bars"></i
          ></span>
        </div>

        <div class="toplogo">
          <center>
            <a href="#"
              ><img
                src="https://www.tamandayu.com/img/logo-top2.png"
                class="image-responsive"
            /></a>
          </center>
        </div>
      </div>

      <div class="but-right">
        <ul>
          <li class="bagkiri">
            <span style="cursor: pointer" @click="showModal = true"
              ><i class="fa fa-search"></i
            ></span>
          </li>
          <li class="bagkanan">
            <a
              type="button"
              class="btn btn-primary btn-sm"
              href="https://staahmax.staah.net/be/indexpackdetail?propertyId=MzkyMg==&individual=true"
              target="_blank"
              style="
                font-size: 10px;
                padding: 0;
                padding-left: 10px;
                padding-right: 10px;
                padding-top: 5px;
                padding-bottom: 5px;
                border-radius: 15px;
                background-color: #ad9d6e;
                border-color: #ad9d6e;
              "
            >
              BOOK HOTEL NOW
            </a>
          </li>
        </ul>
      </div>
      <!-- End Navigation -->
    </header>

    <modal-component
      v-if="showModal"
      :onClose="
        () => {
          showModal = false;
        }
      "
      :title="'Search'"
    >
      <template v-slot:body>
        <div class="row">
          <div class="col-12">
            <input
              type="text"
              class="form-control form-control-sm"
              placeholder="Search Value"
              v-model="searchValue"
            />
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-12 d-flex justify-content-end">
            <button class="btn btn-sm btn-info" @click="search()">
              <span class="fa fa-search"></span> Search
            </button>
          </div>
        </div>
      </template>
    </modal-component>
  </div>
</template>

<script setup>
import { sideBarStore } from "../stores/sideBarStore.js";

const store = sideBarStore();
</script>

<script>
import ModalComponent from "@/common/modal_component.vue";
import router from "@/router.js";

export default {
  components: { ModalComponent },
  data() {
    return {
      showModal: false,
      searchValue: "",
    };
  },
  methods: {
    goTopromotions() {
      let element = document.getElementById("promo-section");
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    },

    search() {
      this.showModal = false;
      router.push({ path: `/search/${this.searchValue}` });
    },
  },
};
</script>

<style>
.humberger-icon {
  background-color: transparent !important;
  border: none;
  padding: 0;
  font-size: 18px;
  position: relative;
  cursor: pointer;
}

.top-hitam-gradasi {
  width: 100%;
  height: 209px;
  position: fixed;
  top: 0px;
  background-image: url("https://www.tamandayu.com/img/backgr-hitam.png");
  z-index: 199;
}

.butmenu {
  position: fixed;
  z-index: 150;
  top: 20px;
  font-size: 25px;
  color: white;
  left: 5%;
}

.toplogo {
  position: center;

  top: 0px;
}

.but-right {
  width: 180px;
  height: 50px;
  display: flex;
  position: fixed;
  z-index: 200;
  top: 20px;
  font-size: 25px;
  color: white;
  right: 4%;
}

.bagkiri {
  top: 23px;
  width: 50px;
  height: 50px;
  display: flex;
  position: fixed;
  z-index: 250;
  font-size: 25px;
  color: white;
  right: 140px;
}

.bagkanan {
  top: 15px;
  width: 130px;
  height: 50px;
  right: 20px;
  position: fixed;
  float: left;
  z-index: 250;
}

.bagkiri a {
  color: white;
}

.bagkiri a:hover {
  color: rgb(139, 116, 73);
}

@media (max-width: 600px) {
  .humberger-icon {
    top: 20px;
  }
}
</style>
