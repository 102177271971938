<template>
  <div class="gallery-area default-padding" style="background-color: #e0ddddd7">
    <div class="container">
      <div class="gallery-items-area text-center">
        <div class="row">
          <div class="col-md-12 col-md-offset-2">
            <div class="site-heading text-center">
              <h2>Amenities</h2>
              <span class="devider"></span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="row text-center masonary">
              <!-- Single Item -->
              <div
                v-for="(item, index) in amenities"
                :key="index"
                class="pf-item development capital col-md-4 col-xs-12 mt-3"
              >
                <div class="item">
                  <router-link :to="`/amenities/${item.path}`">
                    <img :src="item.thumbnail" alt="Thumb" />
                    <div class="item-inner">
                      <h4>{{ item.title }}</h4>
                    </div>
                  </router-link>
                </div>
              </div>
              <!-- End Single Item -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      amenities: [
        {
          title: "VIP Lockers",
          path: "vip-lockers",
          thumbnail:
            "./images/vip-lockers-thumbnail.jpeg",
        },
        {
          title: "The Pro Shop at Taman Dayu",
          path: "the-pro-shop-at-taman-dayu",
          thumbnail:
            "./images/pro-shop-thumbnail.jpeg",
        },
        {
          title: "Taman Dayu Spa",
          path: "taman-dayu-spa",
          thumbnail:
            "./images/spa-thumbnail.jpeg",
        },
        {
          title: "Country Club Swimming Pools",
          path: "country-club-swimming-pool",
          thumbnail:
            "./images/swimming-pool-thumbnail.jpeg",
        },
        {
          title: "Country Club Gym",
          path: "country-club-gym",
          thumbnail:
            "https://www.tamandayu.com/_assets/images/uploads/about_us/1382348937_UHP5AJWNgW0TmVQdSqEp.jpg",
        },
        {
          title: "Sky Terrace Cafe",
          path: "sky-terrace-cafe",
          thumbnail:
            "./images/sky-terrace-cafe-thumbnail.jpeg",
        },
      ],
    };
  },
};
</script>
