<template>
  <div>
    <div
      v-if="posts.length > 0"
      class="site-title-area text-center shadow dark bg-fixed text-light"
      :style="`background-image: url(${posts[0].categoryThumbnail});
        background-size: cover;
        background-position: center;padding: 170px 0;`"
    >
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h1>{{ $route.params.searchValue }}</h1>
          </div>
        </div>
      </div>
    </div>

    <div
      v-else
      class="site-title-area text-center shadow dark bg-fixed text-light"
      :style="`background: grey;
        background-size: cover;
        background-position: center;padding: 170px 0;`"
    >
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h1>{{ $route.params.searchValue }}</h1>
          </div>
        </div>
      </div>
    </div>

    <div class="blog-area default-padding bottom-less">
      <div class="container">
        <div class="blog-items row">
          <div v-if="!isLoading && posts.length === 0">
            <not-found-content />
          </div>

          <div
            v-else
            class="col-md-4 single-item"
            v-for="(item, index) in posts"
            :key="index"
          >
            <div class="item">
              <div class="thumb">
                <router-link
                  :to="`/${item.category.replaceAll(' ', '-').toLowerCase()}/${
                    item.path
                  }`"
                >
                  <img
                    style="height=500px"
                    :src="
                      item.thumbnail ||
                      'https://www.tamandayu.com/_assets/images/uploads/about_us/1382349118_AIwwAmlyUM9fA8zzR6VW.jpg'
                    "
                    alt="Thumb"
                /></router-link>
              </div>
              <div class="info">
                <h4>
                  <router-link
                    :to="`/${item.category
                      .replaceAll(' ', '-')
                      .toLowerCase()}/${item.path}`"
                    >{{ item.title }}</router-link
                  >
                </h4>
                <router-link
                  :to="`/${item.category.replaceAll(' ', '-').toLowerCase()}/${
                    item.path
                  }`"
                  class="btn btn-theme border btn-md"
                  >Read More</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getListPost } from "../../common/datasource";
import NotFoundContent from "./not_found_content.vue";

export default {
  components: { NotFoundContent },
  data() {
    return {
      posts: [],
      isLoading: true,
    };
  },
  watch: {
    $route() {
      this.getPosts();
    },
  },
  async mounted() {
    await this.getPosts();
  },
  methods: {
    async getPosts() {
      this.isLoading = true;
      const searchValue = this.$route.params.searchValue;
      this.posts = await getListPost(null, null, searchValue);
      this.isLoading = false;
    },
  },
};
</script>
