import { defineStore } from 'pinia';
import axios from 'axios'
import jwtDecode from 'jwt-decode'
import constants from "../../common/constants";

export const authStore = defineStore('auth', {
    state: () => {
        return {
            token: null,
            user: null,
        }
    },
    actions: {

        async login(credentials) {
            try {
                const response = await axios.post(constants.login, credentials);

                const token = response.data.token
                const user = jwtDecode(token)
                this.token = token;
                this.user = user;
                localStorage.setItem('token', token)

                return token;
            } catch (error) {
                console.log(error)
            }
        },

        async getUserFromToken() {
            const token = localStorage.getItem('token');
            const user = jwtDecode(token);
            return user;
        },

        async logout() {
            this.token = localStorage.getItem('token')
            this.user = null;
            localStorage.removeItem('token')
        },
    },
})