<template>
  <div class="table-responsive mt-3">
    <div class="row mb-3 d-flex justify-content-end">
      <div class="col-md-6">
        <input
          type="text"
          class="form-control"
          placeholder="Search..."
          v-model="searchValue"
        />
      </div>
    </div>
    <Vue3EasyDataTable
      :headers="store.table.headers"
      :items="store.table.items"
      :search-field="store.searchFields"
      :search-value="searchValue"
    >
      <template #empty-message>
        <img
          src="https://i.pinimg.com/originals/94/fd/2b/94fd2bf50097ade743220761f41693d5.gif"
          style="width: 100px; height: 80px"
        />
      </template>
      <template #item-actions="{ data }">
        <span v-for="(action, index) in store.actions" :key="index">
          <button
            v-if="action.type === 'DELETE'"
            class="btn btn-sm btn-danger ml-2"
            v-on:click="onDelete(data)"
          >
            <span class="fa fa-trash"></span> Delete
          </button>
          <button
            v-if="action.type === 'EDIT'"
            class="btn btn-sm btn-warning ml-2"
            v-on:click="onClickEdit(data)"
          >
            <span class="fa fa-pencil"></span> Edit
          </button>
        </span>
      </template>

      <template #item-thumbnail="{ data }">
        <a :href="data.thumbnail" target="_blank" class="btn btn-sm btn-info">
          <i class="fa fa-image"></i> Show Image</a
        >
      </template>

      <template #item-isActive="{ data }">
        <p class="custom-control custom-switch">
          <input
            class="custom-control-input"
            :id="'switch' + data.id"
            type="checkbox"
            :checked="data.isActive ? true : false"
            @change="store.onChangeStatus(data)"
          />
          <label
            class="custom-control-label font-italic"
            :for="'switch' + data.id"
            >{{ data.isActive ? "Active" : "Deactive" }}</label
          >
        </p>
      </template>
    </Vue3EasyDataTable>
  </div>
</template>

<script>
import constants from "./constants";
import Vue3EasyDataTable from "vue3-easy-data-table";
import "vue3-easy-data-table/dist/style.css";

export default {
  components: { Vue3EasyDataTable },
  props: {
    store: {
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      baseUrl: constants.BASE_URL,
      searchValue: "",
    };
  },

  async mounted() {
    await this.store.getListData();
  },

  methods: {
    filterData(listData) {
      return listData.filter((data) => !data.hideFromList);
    },

    getColumnLength() {
      const list = this.store.columns.filter((data) => !data.hideFromList);

      if (!this.store.actions) {
        return list.length;
      } else {
        return list.length + this.store.actions.length;
      }
    },

    async onDelete(data) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.store.onDelete(data);
          this.$toast.success("Data deleted successfully");
        }
      });
    },

    onClickEdit(data) {
      this.store.onEdit(data);
    },
  },
};
</script>

<style scoped>
.custom-control-input-success:checked ~ .custom-control-label::before {
  background-color: #28a745 !important;
  border-color: #28a745 !important;
}

.custom-control-input-danger:checked ~ .custom-control-label::before {
  background-color: #dc3545 !important;
  border-color: #dc3545 !important;
}

.custom-control-input-warning:checked ~ .custom-control-label::before {
  background-color: #ffc107 !important;
  border-color: #ffc107 !important;
}

.custom-control-input-info:checked ~ .custom-control-label::before {
  background-color: #17a2b8 !important;
  border-color: #17a2b8 !important;
}

/* Large toggl switches */
.custom-switch-lg .custom-control-label::before {
  left: -2.25rem;
  width: 3rem;
  border-radius: 1.5rem;
}

.custom-switch-lg .custom-control-label::after {
  top: calc(0.25rem + 3px);
  left: calc(-2.25rem + 4px);
  width: calc(1.5rem - 6px);
  height: calc(1.5rem - 6px);
  border-radius: 1.5rem;
}

.custom-switch-lg .custom-control-input:checked ~ .custom-control-label::after {
  transform: translateX(1.4rem);
}

.custom-switch-lg .custom-control-label::before {
  height: 1.5rem;
}

.custom-switch-lg .custom-control-label {
  padding-left: 1.5rem;
  line-height: 1.7rem;
}
</style>
