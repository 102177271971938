<template>
  <div>
    <section class="vh-100" style="background-color: #a39161">
      <div class="container py-5 h-100">
        <div class="row d-flex justify-content-center align-items-center h-100">
          <div class="col col-xl-6">
            <div class="card" style="border-radius: 1rem">
              <div class="row g-0">
                <div class="col-md-6 col-lg-12 d-flex align-items-center">
                  <div class="card-body p-4 p-lg-5 text-black">
                    <Form @submit="handleLogin" :validation-schema="validation">
                      <div class="d-flex justify-content-center mb-3 pb-1">
                        <i
                          class="fas fa-cubes fa-2x me-3"
                          style="color: #ff6219"
                        ></i>
                        <img
                          src="https://www.tamandayu.magnamediadesign.com/old/assets/logo.png"
                          alt="My Image"
                        />
                      </div>

                      <div class="form-outline mb-4">
                        <label class="form-label" for="form2Example17"
                          >Email address</label
                        >
                        <Field
                          type="email"
                          class="form-control form-control-lg"
                          v-model="email"
                          name="email"
                        />
                        <ErrorMessage name="email" style="color: red" />
                      </div>

                      <div class="form-outline mb-4">
                        <label class="form-label" for="form2Example27"
                          >Password</label
                        >
                        <Field
                          type="password"
                          class="form-control form-control-lg"
                          v-model="password"
                          name="password"
                        />
                        <ErrorMessage name="password" style="color: red" />
                      </div>

                      <div class="pt-1 mb-4">
                        <button
                          class="btn btn-dark btn-lg btn-block"
                          type="submit"
                        >
                          Login
                        </button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import * as yup from "yup";
import router from "@/router";
import { authStore } from "./authStore";

const VALIDATION = {
  email: yup.string().required().email(),
  password: yup.string().required(),
};
export default {
  setup() {
    const store = authStore();
    return {
      store,
    };
  },
  name: "LoginPage",
  data() {
    return {
      email: "",
      password: "",
      validation: VALIDATION,
    };
  },
  methods: {
    async handleLogin() {
      const payload = {
        email: this.email,
        password: this.password,
      };

      const token = await this.store.login(payload);

      if (token) {
        router.push({ path: "/admin/posts" });
      } else {
        this.$toast.error("Invalid username or password");
      }
    },
  },
};
</script>
