<template>
  <div>
    <div class="pagetitle">
      <h1>Categories</h1>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body pt-4">
            <div class="d-flex justify-content-end">
              <button
                class="btn btn-sm btn-success"
                type="button"
                id="show-modal"
                @click="store.showModal = true"
              >
                <i class="fa fa-plus-circle"></i> Create
              </button>
            </div>

            <list-data :id="'category-data'" :store="store" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="store.showModal">
      <modal-component
        :onClose="
          () => {
            store.showModal = false;
            store.clearValue();
          }
        "
        :title="modalTitle"
      >
        <template v-slot:body>
          <form-component :store="store" />
        </template>
      </modal-component>
    </div>
  </div>
</template>
<script setup>
import { categoryStore } from "./category_store";
const store = categoryStore();
</script>

<script>
// import { categoryStore } from "./category_store";
import ListData from "@/common/list_data.vue";
import ModalComponent from "@/common/modal_component.vue";
import FormComponent from "@/common/form_component.vue";

export default {
  components: { ListData, ModalComponent, FormComponent },
  data() {
    return {
      modalTitle: "Form Category",
    };
  },
};
</script>
