import axios from 'axios';
import router from '../router';
import constants from "./constants";

const httpClient = axios.create({
    baseURL: constants.API_BASE_URL,
});

httpClient.interceptors.response.use(
    response => response,
    error => {
        if (error.response.status === 401) {
            localStorage.removeItem('token')
            router.push('/login');
        }
        return Promise.reject(error);
    }
);

export default httpClient;
