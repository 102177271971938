<template>
  <div>
    <Form @submit="onSave">
      <div
        class="form-group"
        v-for="(column, index) in store.columns"
        :key="index"
      >
        <div v-if="column.type === 'text'">
          <label :for="column.key">{{ column.label }}</label>
          <Field
            type="text"
            class="form-control"
            :id="column.key"
            :placeholder="column.label"
            v-model="column.value"
            :name="column.key"
            :rules="column.rules"
          />
          <ErrorMessage :name="column.key" style="color: red" />
        </div>

        <div v-if="column.type === 'password' && !store.isEdit">
          <label :for="column.key">{{ column.label }}</label>
          <Field
            type="password"
            class="form-control"
            :id="column.key"
            :placeholder="column.label"
            v-model="column.value"
            :name="column.key"
            :rules="column.rules"
          />
          <ErrorMessage :name="column.key" style="color: red" />
        </div>

        <div
          class="custom-control custom-switch custom-switch-lg"
          v-if="column.type === 'boolean'"
        >
          <input
            class="custom-control-input"
            :id="column.key"
            type="checkbox"
            :name="column.key"
            :value="column.value"
            :checked="column.value ? true : false"
            v-on:change="column.value = $event.target.checked"
          />
          <label class="custom-control-label" :for="column.key">{{
            column.label
          }}</label>
        </div>

        <div v-if="column.type === 'img'">
          <label :for="column.key">{{ column.label }}</label>
          <div v-if="column.value" class="m-2">
            <a :href="column.value" target="_blank" class="btn btn-sm btn-info">
              <i class="fa fa-image"></i> Show Image</a
            >
          </div>
          <Field
            type="file"
            class="form-control"
            :id="column.key"
            :placeholder="column.label"
            :name="column.key"
            :rules="column.rules"
            @change="onUploadFile($event, column)"
          />
          <ErrorMessage :name="column.key" style="color: red" />
        </div>

        <div v-if="column.type === 'option'">
          <label :for="column.key">{{ column.label }}</label>
          <Select2
            v-model="column.value"
            :options="column.options"
            :placeholder="'Select Post Categories'"
            :settings="{ multiple: true }"
          />
        </div>
      </div>
      <div class="d-flex justify-content-end">
        <button
          type="submit"
          class="btn btn-sm btn-info"
          :disabled="store.isSaveLoading"
        >
          <span
            v-if="store.isSaveLoading"
            class="spinner-border spinner-border-sm"
          ></span>
          <span> <span class="fa fa-save"></span> Submit </span>
        </button>
      </div>
    </Form>
  </div>
</template>
<script>
import { uploadFile } from "./datasource";
import Select2 from "vue3-select2-component";
export default {
  components: { Select2 },
  props: {
    store: {
      required: true,
    },
  },

  methods: {
    async onSave() {
      await this.store.onSave();
      this.$toast.success("Data created successfully");
    },

    async onUploadFile(e, target) {
      var files = e.target.files;
      const response = await uploadFile(files[0]);
      target.value = response.data.image;
    },
  },
};
</script>

<style>
.custom-control-input-success:checked ~ .custom-control-label::before {
  background-color: #28a745 !important;
  border-color: #28a745 !important;
}

.custom-control-input-danger:checked ~ .custom-control-label::before {
  background-color: #dc3545 !important;
  border-color: #dc3545 !important;
}

.custom-control-input-warning:checked ~ .custom-control-label::before {
  background-color: #ffc107 !important;
  border-color: #ffc107 !important;
}

.custom-control-input-info:checked ~ .custom-control-label::before {
  background-color: #17a2b8 !important;
  border-color: #17a2b8 !important;
}

/* Large toggl switches */
.custom-switch-lg .custom-control-label::before {
  left: -2.25rem;
  width: 3rem;
  border-radius: 1.5rem;
}

.custom-switch-lg .custom-control-label::after {
  top: calc(0.25rem + 3px);
  left: calc(-2.25rem + 4px);
  width: calc(1.5rem - 6px);
  height: calc(1.5rem - 6px);
  border-radius: 1.5rem;
}

.custom-switch-lg .custom-control-input:checked ~ .custom-control-label::after {
  transform: translateX(1.4rem);
}

.custom-switch-lg .custom-control-label::before {
  height: 1.5rem;
}

.custom-switch-lg .custom-control-label {
  padding-left: 1.5rem;
  line-height: 1.7rem;
}
</style>
