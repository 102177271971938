import { defineStore } from 'pinia';
import * as Yup from "yup";
import { createCategory, getListCategory, patchCategory } from "@/common/datasource";
import moment from 'moment';

export const categoryStore = defineStore('category', {
    state: () => {
        return {
            name: 'category',
            pinToMenu: false,
            isSaveLoading: false,
            showModal: false,
            isLoadingData: false,
            listData: [],
            isEdit: false,
            categoryId: '',
            selectedData: {},
            columns: [
                {
                    key: "name",
                    label: "Name",
                    type: "text",
                    tableHeaderStyle: [{ width: "50%" }],
                    tableContentClass: ["text-center"],
                    value: "",
                    rules: Yup.string().required(),
                },
                {
                    key: "thumbnail",
                    label: "Thumbnail",
                    type: "img",
                    tableHeaderStyle: [{ width: "20%" }],
                    tableContentClass: ["text-center"],
                    value: "",
                    rules: '',
                },
            ],
            actions: [
                {
                    type: 'EDIT',
                },
                {
                    type: 'DELETE',
                },
            ],
            table: {
                headers: [
                    { text: "Name", value: "name", sortable: true },
                    { text: "Thumbnail", value: "thumbnail" },
                    { text: "Is Active", value: "isActive" },
                    { text: "Updated By", value: "updatedBy" },
                    { text: "Last updated", value: "updatedAt" },
                    { text: "Actions", value: "actions" },
                ],
                items: []
            },
            searchFields: ['name'],
        }
    },
    actions: {
        async onSave() {
            this.isSaveLoading = true;
            const payload = {
                showPosts: 1
            };

            this.columns.forEach(column => {
                payload[column.key] = column.value;
            })

            if (this.isEdit) {
                payload.id = this.categoryId;
                await patchCategory(payload);
            }
            else {
                await createCategory(payload);
            }


            this.isSaveLoading = false;
            this.getListData();
            this.showModal = false;
            this.resetForm();
        },

        async getListData() {
            this.table.items = [];
            this.isLoadingData = true;
            this.listData = [];
            this.listData = await getListCategory(1);
            this.isLoadingData = false;

            this.listData.forEach(data => {
                this.table.items.push({
                    name: data.name,
                    updatedBy: data.updatedByName,
                    updatedAt: moment(new Date(data.updated_at)).format('MMMM Do YYYY, h:mm:ss'),
                    data: data
                })
            });
        },

        resetForm() {
            this.columns.forEach(column => {
                if (column.type === 'boolean') {
                    column.value = false;
                } else {
                    column.value = '';
                }
            })
        },

        async onDelete(data) {
            await patchCategory({ id: data.id, isDeleted: 1 });
            this.getListData();
        },

        onEdit(selectedData) {
            this.showModal = true;
            this.isEdit = true;
            this.selectedData = selectedData;
            this.categoryId = selectedData.id;

            this.columns.forEach(column => {
                column.value = selectedData[column.key];
            })
        },

        clearValue() {
            this.columns.forEach(column => {
                column.value = "";
            })
        },

        async onChangeStatus(data) {
            await patchCategory({ id: data.id, isActive: !data.isActive });
            this.getListData();
        },
    },
})