<template>
  <div class="about-area default-padding">
    <div class="container">
      <div class="row">
        <div class="col-md-6 thumb">
          <img
            src="https://www.tamandayu.com/img/bg-our-story.png"
            alt="Thumb"
          />
        </div>
        <div class="col-md-6 tabs-items">
          <div class="info title">
            <h2><b>Our Story</b></h2>

            <h3>
              Inspired by the tropical atmosphere and the stunning panoramic
              views of Mount Welirang.
            </h3>
            <p>
              The Jack Nicklaus Signature Course at Taman Dayu strategically
              located golf club set in the lush green foothills of Mount
              Welirang. The course is part of an integrated resort that covers
              600 hectares of prime land, including an academy of golf, driving
              range, The Golf Club, The Pro Shop, exclusive locker rooms, a Jack
              Nicklaus VIP Locker, The Gym, swimming pools, The Bungalows, The
              Residence, The Clubhouse, and a selection of restaurants and
              lounge, a health spa, banquet and meeting facilities, and mountain
              and forest adventure activities.
            </p>

            <router-link
              to="/about-taman-dayu/our-story"
              class="btn btn-theme border btn-md"
              >Read More</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
