const BASE_URL = "https://magna.tamandayu.com"
const API_BASE_URL = 'https://www.tamandayu.com/magna/api/api'
// const API_BASE_URL = 'https://www.magna.tamandayu.com/api/api'
// const API_BASE_URL = 'https://company-profile-service-7jxi42qfkq-et.a.run.app/api'
const measurementId = "G-R7SHEJJFBD"
const API = {
    login: `${API_BASE_URL}/login`,
    categories: `${API_BASE_URL}/categories`,
    categoriesAdmin: `${API_BASE_URL}/admin/categories`,
    posts: `${API_BASE_URL}/posts`,
    postsAdmin: `${API_BASE_URL}/admin/posts`,
    latestPromo: `${API_BASE_URL}/latest-promo`,
    users: `${API_BASE_URL}/users`,
    upload: `${API_BASE_URL}/upload`,
    configurations: `${API_BASE_URL}/configurations`,
    configurationsBulk: `${API_BASE_URL}/configurations/bulk`,
    catalogs: `${API_BASE_URL}/catalogs`,
}

export default {
    ...API,
    BASE_URL,
    measurementId
}