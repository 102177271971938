<template>
  <div class="fixed-top nav-header pl-3 pr-3 pt-3">
    <div class="row">
      <div class="col-6">
        <span class="logo d-flex align-items-center">
          <img
            src="https://www.magnamediadesign.com/materi-tamandayu/favicon.png"
            alt=""
          />
          <span class="d-none d-lg-block" style="color: #e7ae14"
            >TAMAN DAYU</span
          >
        </span>
      </div>
      <div class="col d-flex justify-content-end align-items-center">
        <span style="color: black; cursor: pointer" @click="onLogout()">
          <span class="fa fa-sign-out-alt"></span>
          <span class="ml-1">Sign Out</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import router from '@/router';
export default {
  methods: {
    onLogout() {
      console.log("aaaa")
      localStorage.removeItem("token");
      router.push("/login");
    },
  },
};
</script>
