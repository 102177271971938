import { defineStore } from 'pinia';
import { getPostsFromAdmin, deletePost, updatePost } from "@/common/datasource";
import router from '@/router';
import moment from 'moment';

export const postStore = defineStore('post', {
    state: () => {
        return {
            name: 'post',
            isLoadingData: false,
            listData: [],
            columns: [
                {
                    key: "title",
                    label: "Title",
                    type: "text",
                    tableHeaderStyle: [{ width: "20%" }],
                    tableContentClass: ["text-center"],
                    value: "",
                },
                {
                    key: "category",
                    label: "Category",
                    type: "text",
                    tableHeaderStyle: [{ width: "20%" }],
                    tableContentClass: ["text-center"],
                    value: false,
                    rules: '',
                },
                {
                    key: "path",
                    label: "Path",
                    type: "text",
                    tableHeaderStyle: [{ width: "20%" }],
                    tableContentClass: ["text-center"],
                    value: "",
                },
            ],
            actions: [
                {
                    type: 'DELETE',
                },
                {
                    type: 'EDIT',
                }
            ],
            table: {
                headers: [
                    { text: "Title", value: "title", sortable: true },
                    { text: "Category", value: "category", sortable: true },
                    { text: "Is Active", value: "isActive" },
                    { text: "Updated By", value: "updatedBy" },
                    { text: "Last updated", value: "updatedAt" },
                    { text: "Actions", value: "actions" },
                ],
                items: []
            },
            searchFields: ['title', 'category'],
        }
    },
    actions: {
        async onSave() {

        },

        async getListData() {
            this.table.items = [];
            this.isLoadingData = true;
            this.listData = await getPostsFromAdmin();
            this.isLoadingData = false;

            this.listData.forEach(data => {
                this.table.items.push({
                    title: data.title,
                    category: data.category,
                    updatedBy: data.createdByName,
                    updatedAt: moment(new Date(data.updated_at)).format('MMMM Do YYYY, h:mm:ss'),
                    data: data
                })
            });
        },

        async onDelete(data) {
            await deletePost(data.id);
            this.getListData();
        },

        async onChangeStatus(data) {
            await updatePost({ id: data.id, isActive: !data.isActive });
            this.getListData();
        },

        onEdit(data) {
            router.push({ path: `/admin/posts/${data.id}/detail` });
        },
    },
})