<template>
  <div>
    <button @click="openPopup" class="wa-butt">
      <img src="/button-whatsapp2.png" style="width: 90%" />
    </button>

    <div id="popup" class="popup">
      <div class="chat-link">
        <span
          @click="closePopup"
          href="#"
          class="close-side close-icon"
          style="color: white; font-size: 24px; cursor: pointer"
          ><i class="fa fa-times-circle"></i></span
        ><br />

        <div class="row mt-2" v-for="(contact, index) in contacts" :key="index">
          <div class="col-12">
            <span class="nama"
              ><a :href="contact.target" target="_blank">{{
                contact.label
              }}</a></span
            ><br />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      contacts: [
        {
          label: "Jack Terrace Resto",
          target:
            "https://api.whatsapp.com/send?phone=628113530234&text=Mau%20order%20di%20Jack%20Terrace%20Resto,%20nih..",
        },
        {
          label: "GOLF Registration",
          target:
            "https://api.whatsapp.com/send?phone=628113635234&text=Mau%20registrasi%20Golf%20dunk..",
        },
        // {
        //   label: "Driving Range Reservation",
        //   target:
        //     "https://api.whatsapp.com/send?phone=6281132290234&text=Mau%20reservasi%20di%20Driving%20Range%20Golf%20ya..",
        // },
        {
          label: "Hotel Reservation",
          target:
            "https://api.whatsapp.com/send?phone=628113080687&text=Mau%20reservasi%20Hotel%20ya..",
        },
        {
          label: "Sales Team Inquiry",
          target:
            "https://api.whatsapp.com/send?phone=628113503121&text=Dear%20Sales%20Team,%0D%0AMohon%20penawarannya%20untuk%20Meeting%20ya..",
        },
      ],
    };
  },
  methods: {
    openPopup() {
      document.getElementById("popup").style.display = "block";
    },

    closePopup() {
      document.getElementById("popup").style.display = "none";
    },
  },
};
</script>

<style>
.bookhotel {
  position: fixed;
  /* width: 100px; */
  height: auto;
  top: 110px;
  right: 8px;
  background: none;
  z-index: 20;
}

.popup {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);

  z-index: 999;
}

.chat-link {
  position: fixed;
  width: 400px;
  height: auto;
  bottom: 114px;
  text-align: right;
  right: 29px;
  background: none;
  z-index: 20;
}

.bulatan {
  padding: 5px 8px;
  background: white;
  border-radius: 30px;
  color: #333;
  font-weight: 800;
  font-size: 12px;
  border: none;
}

.wa-butt {
  border: 0px solid black;
  background: none;
  position: fixed;
  width: 90px;
  height: auto;
  bottom: 20px;
  right: 20px;
  z-index: 20;
}

.nama {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  background: #d3a32e;
  padding: 5px 10px;
  border-radius: 10px;
}
.nama a {
  color: white;
  text-decoration: none;
}
.nama a:hover {
  color: rgb(137 99 7);
}

.btn-book {
  padding: 16px 20px;
  background-color: #d8802d;
  color: #fff;
  font-weight: bold;
  font-size: 1.3em;
  line-height: 1.3em;
  display: inline-block;
  padding: 8px 10px !important;
}
</style>
