<template>
  <div>
    <div class="pagetitle">
      <h1>Users</h1>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body pt-4">
            <div class="d-flex justify-content-end">
              <button
                class="btn btn-sm btn-info"
                type="button"
                id="show-modal"
                @click="store.showModal = true"
              >
                Create
              </button>
            </div>

            <list-data :id="'user-data'" :store="store" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="store.showModal">
      <modal-component
        :onClose="
          () => {
            store.showModal = false;
            store.isEdit = false;
            store.resetForm();
          }
        "
        :title="modalTitle"
        :size="'LARGE'"
      >
        <template v-slot:body>
          <form-component :store="store" />
        </template>
      </modal-component>
    </div>
  </div>
</template>
<script setup>
import { userStore } from "./user_store";
const store = userStore();
</script>

<script>
import ListData from "@/common/list_data.vue";
import ModalComponent from "@/common/modal_component.vue";
import FormComponent from "@/common/form_component.vue";

export default {
  components: { ListData, ModalComponent, FormComponent },
  data() {
    return {
      modalTitle: "Form User",
    };
  },
};
</script>
