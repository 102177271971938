import { defineStore } from 'pinia';
import * as Yup from "yup";
import { createUser, getListUser, deleteUser, patchUser, getListCategory } from "@/common/datasource";

export const userStore = defineStore('user', {
    state: () => {
        return {
            name: 'user',
            pinToMenu: false,
            isSaveLoading: false,
            showModal: false,
            isLoadingData: false,
            listData: [],
            columns: [
                {
                    key: "name",
                    label: "Name",
                    type: "text",
                    value: "",
                    rules: Yup.string().required(),
                },
                {
                    key: "email",
                    label: "Email",
                    type: "text",
                    value: "",
                    rules: Yup.string().required().email(),
                },
                {
                    key: "password",
                    label: "Password",
                    type: "password",
                    value: "",
                    rules: Yup.string().required().min(6),
                    hideFromList: true,
                    disableEdit: true
                },
                {
                    key: "manageCategories",
                    label: "Manage Categories",
                    type: "boolean",
                    value: false,
                },
                {
                    key: "manageUsers",
                    label: "Manage Users",
                    type: "boolean",
                    value: false,
                },
                {
                    key: "manageConfigurations",
                    label: "Manage Configurations",
                    type: "boolean",
                    value: false,
                },
                {
                    key: "postPermissions",
                    label: "Posts allowed to manage",
                    type: "option",
                    options: [],
                    value: [],
                },
            ],
            actions: [
                {
                    type: 'DELETE',
                },
                {
                    type: 'EDIT',
                }
            ],
            table: {
                headers: [
                    { text: "Name", value: "name", sortable: true },
                    { text: "Email", value: "email", sortable: true },
                    { text: "Is Active", value: "isActive" },
                    { text: "Actions", value: "actions" },
                ],
                items: []
            },
            searchFields: ['name', 'email'],
            selectedData: {},
            userId: '',
            isEdit: false,
        }
    },
    actions: {
        async getListData() {
            this.table.items = [];
            this.isLoadingData = true;
            this.listData = [];
            this.listData = await getListUser();
            this.isLoadingData = false;

            this.listData.forEach(data => {
                this.table.items.push({
                    name: data.name,
                    email: data.email,
                    data: data
                })
            });

            await this.getListCategory();
        },

        async getListCategory() {
            const categories = await getListCategory();
            const categoryOption = this.columns.find(x => x.key === 'postPermissions');
            categoryOption.options = [];

            categories.forEach(category => {
                categoryOption.options.push({
                    id: category.id,
                    text: category.name
                })
            })
        },

        resetForm() {
            this.columns.forEach(column => {
                if (column.type === 'boolean') {
                    column.value = false;
                } else {
                    column.value = '';
                }
            })
        },

        async onSave() {
            this.isSaveLoading = true;
            const payload = {};
            this.columns.forEach(column => {
                payload[column.key] = column.value;
            })

            if (this.isEdit) {
                payload.id = this.userId;
                await patchUser(payload.id, payload);

            }
            else {
                await createUser(payload);
            }

            this.isSaveLoading = false;
            this.getListData();
            this.showModal = false;
            this.resetForm();
        },

        async onDelete(data) {
            await deleteUser(data.id);
            this.getListData();
        },

        async onChangeStatus(data) {
            await patchUser(data.id, { isActive: !data.isActive });
            this.getListData();
        },

        async onEdit(selectedData) {
            this.showModal = true;
            this.isEdit = true;
            this.selectedData = selectedData;
            this.userId = selectedData.id;

            this.columns.forEach(column => {
                if (column.type == 'boolean') {
                    column.value = selectedData[column.key] == 1 ? true : false;
                }
                else {
                    column.value = selectedData[column.key];
                }
            })
        },

        clearValue() {
            this.columns.forEach(column => {
                column.value = "";
            })
        },

    },
})