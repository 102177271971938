<template>
  <div>
    <div class="pagetitle">
      <h1>Posts</h1>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body pt-4">
            <div class="d-flex justify-content-end">
              <router-link to="/admin/posts/create" class="btn btn-sm btn-info">Create</router-link>
            </div>

            <list-data :id="'category-data'" :store="store" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { postStore } from "./post_store";
const store = postStore();
</script>

<script>
import ListData from "@/common/list_data.vue";

export default {
  components: { ListData },
};
</script>
