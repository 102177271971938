<template>
  <div
    style="margin-top: 20%; margin-bottom: 5%"
    class="d-flex justify-content-center"
  >
    <img :src="image" alt="" />
  </div>
</template>
<script>
export default {
  data() {
    return {
      image: "./images/under-construction.png",
    };
  },
};
</script>
