<template>
  <div>
    <div style="text-align: center">
      <img
        src="https://www.tamandayu.com/_assets/img/golf-course-map.png"
        alt="golf course map"
        usemap="#Map"
        border="0"
        height="232"
        width="545"
      />
      <map name="Map" id="Map">
        <area
          v-for="(map, index) in maps"
          :key="index"
          shape="rect"
          class="fancybox"
          mce_shape="rect"
          :coords="map.coords"
          :mce_coords="map.coords"
          rel="group"
          @click="onClickArea(index)"
          style="cursor: pointer"
        />
      </map>
    </div>

    <div v-if="showModal">
      <modal-component
        :onClose="
          () => {
            showModal = false;
          }
        "
        :title="'Golf Course Maps'"
        :size="'LARGE'"
      >
        <template v-slot:body>
          <carousel :items-to-show="1" :modelValue="selectedArea">
            <slide v-for="(map, index) in maps" :key="index">
              <img :src="map.img" alt="" />
            </slide>

            <template #addons>
              <navigation />
              <pagination />
            </template>
          </carousel>
        </template>
      </modal-component>
    </div>
  </div>
</template>
<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import ModalComponent from "@/common/modal_component.vue";

export default {
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
    ModalComponent,
  },
  data() {
    return {
      showModal: false,
      selectedArea: 0,
      maps: [
        {
          coords: "236,42,262,68",
          img: "https://www.tamandayu.com/_assets/img/golf_map/hole1.jpg",
        },
        {
          coords: "304,20,330,46",
          img: "https://www.tamandayu.com/_assets/img/golf_map/hole2.jpg",
        },
        {
          coords: "378,22,404,48",
          img: "https://www.tamandayu.com/_assets/img/golf_map/hole3.jpg",
        },
        {
          coords: "459,27,485,53",
          img: "https://www.tamandayu.com/_assets/img/golf_map/hole4.jpg",
        },
        {
          coords: "461,73,487,99",
          img: "https://www.tamandayu.com/_assets/img/golf_map/hole5.jpg",
        },
        {
          coords: "434,104,460,130",
          img: "https://www.tamandayu.com/_assets/img/golf_map/hole6.jpg",
        },
        {
          coords: "383,95,409,121",
          img: "https://www.tamandayu.com/_assets/img/golf_map/hole7.jpg",
        },
        {
          coords: "301,75,327,101",
          img: "https://www.tamandayu.com/_assets/img/golf_map/hole8.jpg",
        },
        {
          coords: "253,78,279,104",
          img: "https://www.tamandayu.com/_assets/img/golf_map/hole9.jpg",
        },
        {
          coords: "146,127,172,153",
          img: "https://www.tamandayu.com/_assets/img/golf_map/hole10.jpg",
        },
        {
          coords: "70,125,92,147",
          img: "https://www.tamandayu.com/_assets/img/golf_map/hole11.jpg",
        },
        {
          coords: "81,147,103,169",
          img: "https://www.tamandayu.com/_assets/img/golf_map/hole12.jpg",
        },
        {
          coords: "71,168,93,190",
          img: "https://www.tamandayu.com/_assets/img/golf_map/hole13.jpg",
        },
        {
          coords: "20,173,46,199",
          img: "https://www.tamandayu.com/_assets/img/golf_map/hole14.jpg",
        },
        {
          coords: "85,189,111,215",
          img: "https://www.tamandayu.com/_assets/img/golf_map/hole15.jpg",
        },
        {
          coords: "173,139,199,165",
          img: "https://www.tamandayu.com/_assets/img/golf_map/hole16.jpg",
        },
        {
          coords: "275,142,301,168",
          img: "https://www.tamandayu.com/_assets/img/golf_map/hole17.jpg",
        },
        {
          coords: "261,111,287,137",
          img: "https://www.tamandayu.com/_assets/img/golf_map/hole18.jpg",
        },
      ],
    };
  },

  methods: {
    onClickArea(index){
      this.showModal = true;
      this.selectedArea = index;
    }
  },
};
</script>
