<template>
  <VueAwesomeSideBar
    v-model:collapsed="store.collapsed"
    :menu="store.menus"
    :closeOnClickOutSide="true"
    :BottomMiniMenuBtn="false"
    vueRouterEnabel
  >
    <template #header>
      <div class="row p-2">
        <div class="col-12 d-flex justify-content-end">
          <span
            @click="store.buttonCollapse"
            href="#"
            class="close-side"
            style="color: red; font-size: 20px"
            ><i class="fa fa-times"></i
          ></span>
        </div>
      </div>
    </template>
  </VueAwesomeSideBar>
</template>

<script setup>
import { sideBarStore } from "../stores/sideBarStore";
const store = sideBarStore();
store.getCategories();
</script>

<style>
.menu-item-base .label .left {
  display: flow-root !important;
  margin-left: 0px !important;
  font-size: 15px;
}

.vas-menu.white-theme.ltr {
  background: url("https://tamandayu.com/img/bg-side.png") no-repeat;
  background-size: cover;
}
</style>
