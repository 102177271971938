<!-- eslint-disable no-unused-vars -->
<template>
  <div>
    <header id="home">
      <!-- Start Navigation -->

      <div class="alas-top">
        <div class="top-choc-gradasi">
          <div class="butmenu">
            <span style="cursor: pointer" @click="store.buttonCollapse"
              ><i class="fa fa-bars"></i
            ></span>
          </div>

          <div class="toplogo">
            <CENTER
              ><a href="#"
                ><img
                  src="https://www.tamandayu.com/img/logo-top2.png"
                  class="image-responsive" /></a
            ></CENTER>
          </div>

          <div class="but-right">
            <ul>
              <li class="bagkiri">
                <span style="cursor: pointer" @click="showModal = true"
                  ><i class="fa fa-search"></i
                ></span>
              </li>
              <li class="bagkanan">
                <a
                  type="button"
                  class="btn btn-primary btn-sm"
                  href="https://staahmax.staah.net/be/indexpackdetail?propertyId=MzkyMg==&individual=true"
                  target="_blank"
                  style="
                    font-size: 10px;
                    padding: 0;
                    padding-left: 10px;
                    padding-right: 10px;
                    padding-top: 5px;
                    padding-bottom: 5px;
                    border-radius: 15px;
                    background-color: #ad9d6e;
                    border-color: #ad9d6e;
                  "
                >
                  BOOK HOTEL NOW
                </a>
              </li>
            </ul>
          </div>

          <CENTER>
            <div class="top-judul">
              {{
                postStoreData.post != null
                  ? postStoreData.post.category.toUpperCase()
                  : ""
              }}
            </div>
          </CENTER>
        </div>
      </div>
      <!-- End Navigation -->
    </header>

    <modal-component
      v-if="showModal"
      :onClose="
        () => {
          showModal = false;
        }
      "
      :title="'Search'"
    >
      <template v-slot:body>
        <div class="row">
          <div class="col-12">
            <input
              type="text"
              class="form-control form-control-sm"
              placeholder="Search Value"
              v-model="searchValue"
            />
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-12 d-flex justify-content-end">
            <button class="btn btn-sm btn-info" @click="search()">
              <span class="fa fa-search"></span> Search
            </button>
          </div>
        </div>
      </template>
    </modal-component>
  </div>
</template>

<script setup>
import { sideBarStore } from "../stores/sideBarStore.js";
import { postStore } from "../stores/postStore.js";

const store = sideBarStore();
// eslint-disable-next-line no-unused-vars
const postStoreData = postStore();
</script>

<script>
import ModalComponent from "@/common/modal_component.vue";
import router from "@/router.js";

export default {
  components: { ModalComponent },
  data() {
    return {
      showModal: false,
      searchValue: "",
      title: "",
    };
  },
  watch: {
    $route() {
      this.getTitle();
    },
  },
  mounted() {
    this.getTitle();
  },
  methods: {
    getTitle() {
      if (this.$route.params.path) {
        this.title = this.$route.params.path.replaceAll("-", " ").toUpperCase();
      }
    },

    goTopromotions() {
      let element = document.getElementById("promo-section");
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    },

    search() {
      this.showModal = false;
      router.push({ path: `/search/${this.searchValue}` });
    },
  },
};
</script>

<style>
.start-content {
  margin-top: 260px;
  font-size: 18px;
  color: black;
}

.top-judul {
  font-family: "Segoe UI", serif;
  width: 300px;
  font-size: 26px;
  color: white;
  text-align: center;
  background: rgb(87, 51, 4);
  padding: 10px 14px 10px 14px;
}

.alas-top {
  background-image: url("https://www.tamandayu.com/img/backgr-putih-gradasi.png");
  position: fixed;
  z-index: 500;
  height: 230px;
  width: 100%;
}
.top-choc-gradasi {
  width: 100%;
  height: 220px;
  /* position:fixed;  */
  /* z-index: 900; */
  /* top:0px; */
  background-image: url("https://www.tamandayu.com/img/backgr-choc-rev.png");
}

.butmenu {
  position: fixed;
  z-index: 150;
  top: 20px;
  font-size: 25px;
  color: white;
  left: 5%;
}

.toplogo {
  position: center;

  top: 0px;
}

.but-right {
  width: 180px;
  height: 50px;
  display: flex;
  position: fixed;
  z-index: 200;
  top: 20px;
  font-size: 25px;
  color: white;
  right: 4%;
}

.bagkiri {
  top: 23px;
  width: 50px;
  height: 50px;
  display: flex;
  position: fixed;
  z-index: 250;
  font-size: 25px;
  color: white;
  right: 140px;
}

.bagkanan {
  top: 10px;
  width: 130px;
  height: 50px;
  right: 20px;
  position: fixed;
  float: left;
  z-index: 250;
}

.bagkiri a {
  color: white;
}

.bagkiri a:hover {
  color: rgb(139, 116, 73);
}
</style>
