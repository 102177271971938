<template>
  <div>
    <div class="pagetitle">
      <h1>{{ isEdit ? "Edit Post" : "Create Post" }}</h1>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body pt-4">
            <Form @submit="onSave">
              <div class="form-group row">
                <label class="col-sm-2 col-form-label pt-0">Title*</label>
                <div class="col-sm-6">
                  <Field
                    type="text"
                    class="form-control form-control-sm"
                    name="title"
                    v-model="title"
                    :rules="requiredValidator"
                  />
                  <ErrorMessage :name="'title'" style="color: red" />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-2 col-form-label pt-0">Category*</label>
                <div class="col-sm-6">
                  <Select2
                    v-model="selectedCategory"
                    :options="categoryOptions"
                    :placeholder="'Select Category'"
                    @select="onChangeCategory()"
                  />
                </div>
              </div>

              <div class="form-group row" v-if="isShowThumbnail">
                <label class="col-sm-2 col-form-label pt-0">Thumbnail</label>
                <div class="col-sm-6">
                  <div v-if="isEdit && thumbnail" class="mb-1">
                    <a
                      :href="thumbnail"
                      target="_blank"
                      class="btn btn-info btn-sm"
                      >Preview</a
                    >
                  </div>
                  <input
                    type="file"
                    class="form-control form-control-sm"
                    id="thumbnail-file"
                    v-on:change="onUploadFile"
                    style="height: 37px"
                  />
                </div>
              </div>

              <div class="form-group row" v-if="isShowCatalog">
                <label class="col-sm-2 col-form-label pt-0">Catalog</label>
                <div class="col-sm-6">
                  <div v-if="isEdit && catalog" class="mb-1">
                    <a
                      :href="catalog"
                      target="_blank"
                      class="btn btn-info btn-sm"
                      >Preview</a
                    >
                  </div>
                  <input
                    type="file"
                    class="form-control form-control-sm"
                    id="catalog-file"
                    v-on:change="onUploadCatalog"
                    style="height: 37px"
                  />
                </div>
              </div>

              <div class="form-group row" v-if="catalog">
                <label class="col-sm-2 col-form-label pt-0"
                  >Catalog Title*</label
                >
                <div class="col-sm-6">
                  <Field
                    type="text"
                    class="form-control form-control-sm"
                    name="catalogTitle"
                    v-model="catalogTitle"
                    :rules="requiredValidator"
                  />
                  <ErrorMessage :name="'catalogTitle'" style="color: red" />
                </div>
              </div>

              <!-- <ckeditor
                id="editor"
                :editor="editor"
                v-model="content"
                :config="editorConfig"
                @ready="onReady"
              ></ckeditor> -->
              <QuillEditor
                v-if="showEditor"
                theme="snow"
                toolbar="full"
                v-model:content="content"
                contentType="html"
                :modules="modules"
              />

              <div class="d-flex justify-content-end mt-3">
                <button
                  type="submit"
                  class="btn btn-sm btn-info"
                  :disabled="isSaveLoading"
                >
                  <span
                    v-if="isSaveLoading"
                    class="spinner-border spinner-border-sm"
                  ></span>
                  <span> <span class="fa fa-save"></span> Save & Publish </span>
                </button>
                <button
                  @click="onClickCancel()"
                  class="btn btn-sm btn-danger ml-2"
                >
                  <span class="fa fa-times-circle"></span> Cancel
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Select2 from "vue3-select2-component";
import {
  getCategoriesFromAdmin,
  createPost,
  uploadFile,
} from "@/common/datasource";
import * as Yup from "yup";
import router from "@/router";
import { getPostById, updatePost } from "../../common/datasource.js";
import DocumentEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import BlotFormatter from "quill-blot-formatter";
import HtmlEditButton from "quill-html-edit-button";
import ImageUploader from "quill-image-uploader";

const requiredValidator = Yup.string().required();
const CATEGORY_EXCLUDE_THUMBNAIL = ["5"];
const CATEGORY_EXCLUDE_CATALOG = ["5", "1", "2"];

export default {
  components: { Select2, QuillEditor },
  setup: () => {
    const modules = [
      {
        name: "blotFormatter",
        module: BlotFormatter,
        options: {
          /* options */
        },
      },
      {
        name: "htmlEditButton",
        module: HtmlEditButton,
        option: {
          debug: true,
          msg: "Edit the content in HTML format",
          okText: "Okk",
        },
      },
      {
        name: "imageUploader",
        module: ImageUploader,
        options: {
          upload: async (file) => {
            try {
              const response = await uploadFile(file);
              const imageUrl = response.data.image;

              return imageUrl;
            } catch (error) {
              throw new Error("Upload failed");
            }
          },
        },
      },
    ];
    return { modules };
  },
  data() {
    return {
      editor: DocumentEditor,
      title: "",
      requiredValidator: requiredValidator,
      content: "",
      thumbnail: "",
      isShowCatalog: false,
      isShowThumbnail: false,
      catalog: null,
      catalogTitle: "",
      editorConfig: {
        fontSize: {
          options: [9, 10, 12, 14, 16, 18, 20, 22, 24],
        },
        toolbar: {
          items: [
            "heading",
            "|",
            "bold",
            "italic",
            "link",
            "bulletedList",
            "numberedList",
            "|",
            "outdent",
            "indent",
            "|",
            "imageUpload",
            "blockQuote",
            "insertTable",
            "mediaEmbed",
            "undo",
            "redo",
            "alignment",
            "fontBackgroundColor",
            "fontColor",
            "fontFamily",
            "fontSize",
            "selectAll",
            "strikethrough",
            "underline",
            "sourceEditing",
          ],
        },
        language: "en",
        image: {
          toolbar: [
            "imageTextAlternative",
            "toggleImageCaption",
            "imageStyle:inline",
            "imageStyle:block",
            "imageStyle:side",
          ],
        },
        table: {
          contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
        },
        extraPlugins: [
          function (editor) {
            editor.plugins.get("FileRepository").createUploadAdapter = (
              loader
            ) => {
              return {
                upload: async () => {
                  try {
                    const file = await loader.file;
                    const response = await uploadFile(file);
                    const imageUrl = response.data.image;

                    return {
                      default: imageUrl,
                    };
                  } catch (error) {
                    throw new Error("Upload failed");
                  }
                },
                abort: () => {},
              };
            };
          },
        ],
      },
      selectedCategory: "",
      isSaveLoading: false,
      listCategory: [],
      categoryOptions: [],
      isEdit: false,
      postId: "",
      showEditor: false,
    };
  },

  async mounted() {
    this.listCategory = await getCategoriesFromAdmin();
    this.mapCategoryToOption();
    this.isEditRoute();
    this.showEditor = true;
  },

  methods: {
    onReady(editor) {
      editor.ui
        .getEditableElement()
        .parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          editor.ui.getEditableElement()
        );
    },

    onClickCancel() {
      router.push({ path: "/admin/posts" });
    },

    async isEditRoute() {
      const currentRoute = this.$route.path;
      if (currentRoute.includes("detail")) {
        this.isEdit = true;
        await this.getPostDetail();
        this.onChangeCategory();
      }
    },

    async getPostDetail() {
      const postId = this.$route.params.id;
      const post = await getPostById(postId);
      this.title = post.title;
      this.content = post.content;
      this.selectedCategory = post.categoryId;
      this.thumbnail = post.thumbnail;
      this.postId = postId;
      this.catalog = post.catalog;
      this.catalogTitle = post.catalogTitle;
    },

    mapCategoryToOption() {
      for (const category of this.listCategory) {
        this.categoryOptions.push({
          id: category.id,
          text: category.name,
        });
      }
    },

    async onUploadFile() {
      const files = document.getElementById("thumbnail-file").files;
      const result = await uploadFile(files[0]);
      this.thumbnail = result.data.image;
    },

    async onUploadCatalog() {
      const files = document.getElementById("catalog-file").files;
      const result = await uploadFile(files[0]);
      this.catalog = result.data.image;
    },

    onChangeCategory() {
      this.isShowThumbnail = false;
      this.isShowCatalog = false;
      if (
        !CATEGORY_EXCLUDE_THUMBNAIL.includes(this.selectedCategory.toString())
      ) {
        this.isShowThumbnail = true;
      }
      if (
        !CATEGORY_EXCLUDE_CATALOG.includes(this.selectedCategory.toString())
      ) {
        this.isShowCatalog = true;
      }
    },

    async onSave() {
      if (this.selectedCategory === "") {
        this.$toast.error("Please select category");
        return;
      }
      if (this.content === "") {
        this.$toast.error("Post content cannot empty");
        return;
      }
      this.isSaveLoading = true;

      try {
        const payload = {
          title: this.title,
          categoryId: this.selectedCategory,
          content: this.content,
        };

        if (this.isShowThumbnail) {
          payload.thumbnail = this.thumbnail;
        }

        if (this.catalog) {
          payload.catalog = this.catalog;
          payload.catalogDescription = this.catalogTitle;
        }

        if (this.isEdit) {
          payload.id = this.postId;
          await updatePost(payload);
        } else {
          await createPost(payload);
        }

        this.$toast.success("Post has been saved");

        router.go(-1);
      } catch (error) {
        if (error.response.status === 400) {
          console.log(error.response.data.message);
          this.$toast.error(error.response.data.message);
        }
      }

      this.isSaveLoading = false;
    },
  },
};
</script>

<style>
.ql-snow.ql-toolbar button,
.ql-snow .ql-toolbar button {
  background: none;
  border: none;
  cursor: pointer;
  display: inline-block;
  float: left;
  height: 24px;
  padding: 3px 5px;
  width: 30px;
  margin-left: 1px;
  margin-right: 1px;
}
</style>
