<template>
  <div>
    <div class="blog-area default-padding bottom-less">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-md-offset-2">
            <div class="site-heading text-center">
              <h2>Latest News</h2>
              <span class="devider"></span>
            </div>
          </div>
        </div>
        <div class="blog-items row">
          <!-- Single Item -->
          <div
            class="col-md-6 single-item"
            v-for="(item, index) in latestNews"
            :key="index"
          >
            <div class="item">
              <div class="thumb">
                <router-link :to="`/news-&-events/${item.path}`">
                  <img :src="item.thumbnail" alt="Thumb"
                /></router-link>
              </div>
              <div class="info">
                <h4>
                  <router-link :to="`/news-&-events/${item.path}`">{{
                    item.title
                  }}</router-link>
                </h4>
                <router-link
                  :to="`/news-&-events/${item.path}`"
                  class="btn btn-theme border btn-md"
                  href="#"
                  >Read More</router-link
                >
              </div>
            </div>
          </div>
          <!-- End Single Item -->
        </div>
        <div class="row">
          <div class="col-12 d-flex justify-content-center">
            <router-link :to="`/category/latest-news`"
              class="btn btn-theme border btn-md"
              style="background-color: black; color: white !important"
            >
              See More <br> NEWS & EVENTS
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      latestNews: [
        {
          title:
            "Great Golf in a Glorious Setting: The Taman Dayu Championship 2012",
          path: "taman-dayu-championship-2012",
          thumbnail:
            "./images/latest-news-1.jpeg",
        },
        {
          title: "What They Say about the Taman Dayu Championship Course",
          path: "what-they-say-about-the-taman-dayu-championship-course",
          thumbnail:
            "./images/latest-news-2.jpeg",
        },
      ],
    };
  },
};
</script>
