<template>
  <div>
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div
            class="modal-dialog modal-md"
            :class="size === 'LARGE' ? 'modal-lg' : 'modal-md'"
          >
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">{{ title }}</h4>
                <button type="button" class="close" @click="onClose">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body"><slot name="body"></slot></div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    onClose: {
      required: true,
      type: Function,
    },
    title: {
      required: true,
      type: String,
    },
    size: {
      required: false,
      type: String,
      default: "MEDIUM",
    },
  },
  data() {
    return {};
  },
};
</script>

<style>
.modal-mask {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
</style>
