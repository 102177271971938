<template>
  <aside id="sidebar" class="sidebar">
    <ul class="sidebar-nav" id="sidebar-nav">
      <li class="nav-item" v-for="(menu, index) in allowedMenus" :key="index">
        <router-link
          class="nav-link"
          :class="{ collapsed: menu.collapsed }"
          :to="menu.url"
          v-on:click="onChangeMenu(menu)"
          ><span
            ><span :class="menu.icon"></span> {{ menu.label }}</span
          ></router-link
        >
      </li>
    </ul>
  </aside>
</template>

<script>
import { authStore } from "./auth/authStore";

export default {
  setup() {
    const store = authStore();
    return {
      store,
    };
  },
  data() {
    return {
      menus: [
        {
          key: "categories",
          collapsed: true,
          label: "Categories",
          url: "/admin/categories",
          icon: "fa fa-icons",
        },
        {
          key: "posts",
          collapsed: false,
          label: "Posts",
          url: "/admin/posts",
          icon: "fa fa-list",
        },
        {
          key: "users",
          collapsed: true,
          label: "Users",
          url: "/admin/users",
          icon: "fa fa-users",
        },
        {
          key: "configurations",
          collapsed: true,
          label: "Configuration",
          url: "/admin/configurations",
          icon: "fa fa-cogs",
        },
      ],
      allowedMenus: [],
    };
  },

  mounted() {
    this.mapMenus();
  },
  methods: {
    async mapMenus() {
      const user = await this.store.getUserFromToken();
      console.log(user);

      for (let menu of this.menus) {
        if (menu.key === "categories" && user.manageCategories === 0) {
          continue;
        }

        if (menu.key === "configurations" && user.manageConfigurations === 0) {
          continue;
        }

        if (menu.key === "users" && user.manageUsers === 0) {
          continue;
        }

        if (menu.key === "posts" && user.postPermissions.length === 0) {
          continue;
        }

        this.allowedMenus.push(menu);
      }
    },

    onChangeMenu(activeMenu) {
      this.menus.forEach((menu) => {
        menu.collapsed = true;
      });

      activeMenu.collapsed = false;
    },
  },
};
</script>
