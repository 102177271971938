import { defineStore } from 'pinia';
import { getListConfiguration } from "@/common/datasource";

export const configurationStore = defineStore('configuration', {
    state: () => {
        return {
            name: 'configuration',
            configurations: null
        }
    },
    actions: {
        async getConfiguration() {
            this.configurations = await getListConfiguration();
        },

        findByKey(key) {
            return this.configurations.find(x => x.key === key).value
        }
    },
})