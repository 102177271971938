<template>
  <footer class="bg-imgfoo text-light">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <center>
            <ul class="foo_medsoc">
              <li class="medsoc_item">
                <a target="_blank" href="https://www.facebook.com/taman.dayu"
                  ><img src="https://tamandayu.com/img/fb-icon.png"
                /></a>
              </li>
              <li class="medsoc_item">
                <a
                  target="_blank"
                  href="https://www.instagram.com/tamandayugolfresort/"
                  ><img src="https://tamandayu.com/img/insta-icon.png"
                /></a>
              </li>
              <li class="medsoc_item">
                <a
                  target="_blank"
                  href="https://www.youtube.com/channel/UCDxrlNhcHZFGULQRgosbRRQ"
                  ><img src="https://tamandayu.com/img/youtube-icon.png"
                /></a>
              </li>
              <li class="medsoc_item">
                <a
                  target="_blank"
                  href="https://www.tiktok.com/@tamandayugolfresort"
                  ><img src="https://tamandayu.com/img/tiktok-icon.png"
                /></a>
              </li>
              <li class="medsoc_item">
                <a
                  target="_blank"
                  href="https://www.google.com/maps/place/Taman+Dayu+Golf+Club+%26+Resort/@-7.7030001,112.6751895,17z/data=!4m9!3m8!1s0x2dd7d98da8d8e00b:0xa16e244b9699f84f!5m2!4m1!1i2!8m2!3d-7.7033071!4d112.6746366!16s%2Fg%2F122fjbj8?hl=en&entry=ttu"
                  ><img src="https://tamandayu.com/img/loc-icon.png"
                /></a>
              </li>
            </ul>

            <div class="foo_coko">
              Jl. Raya Surabaya-Malang Km.48 Pandaan 67156 Pasuruan, East Java -
              Indonesia <br />

              <div class="coko_combotext">
                <div class="row">
                  <div class="col-md-3">
                    <i class="fa fa-phone"></i> +62 343 674 1234
                  </div>
                  <div class="col-md-5 grtepi">
                    <b
                      ><span class="font22"
                        ><i class="fa fa-phone"></i> +62 811 34 88 234</span
                      >
                      (HUNTING)
                    </b>
                  </div>
                  <div class="col-md-4">
                    <i class="fa fa-envelope"></i> info@tamandayu.com
                  </div>
                </div>
              </div>
            </div>
          </center>
        </div>
      </div>
    </div>
    <!-- Start Footer Bottom -->
    <div class="foo-bottom">
      <div class="container">
        <div class="row">
          <div class="col-md-9">
            &copy; Copyright {{ new Date().getFullYear() }}. All Rights Reserved
            by TAMAN DAYU - Golf Club & Resort
          </div>
          <div class="col-md-3">
            <center>
              <ul class="foo_combo">
                <li class="foo_link">
                  <a target="_blank" href="https://www.tamandayu.com/">Home</a>
                </li>
                <li class="foo_link">
                  <a
                    target="_blank"
                    href="https://www.tamandayu.com/about-taman-dayu/our-story"
                    >About Us</a
                  >
                </li>
                <li class="foo_link">
                  <a
                    target="_blank"
                    href="https://www.tamandayu.com/about-taman-dayu/contact-us"
                    >Contact Us</a
                  >
                </li>
              </ul>
            </center>
          </div>
        </div>
      </div>
    </div>
    <!-- End Footer Bottom -->
  </footer>
</template>

<script>
import { configurationStore } from "../stores/configurationStore";

export default {
  setup() {
    const store = configurationStore();
    return {
      store,
    };
  },
};
</script>

<style scoped>
.bg-imgfoo {
  background-image: url("https://tamandayu.com/img/foo_.jpg");
  height: auto;
  background-repeat: no-repeat;
  background-size: cover;
}

.foo_medsoc {
  margin-top: 20%;
  width: 300px;
  height: 50px;
  background: none;
}

.medsoc_item {
  padding: 5px;
  width: 50px;
  height: 50px;
  float: left;
}

.foo_coko {
  width: 90%;
  opacity: 0.9;
  background: rgb(231, 180, 132);
  font-family: "Roboto", serif;
  font-size: 15px;
  color: black;
  padding: 20px;
  margin-top: 10px;
}

.coko_combotext {
  margin-top: 20px;
  width: 80%;
  justify-content: center;
}

.grtepi {
  border-left: 1px solid white;
  border-right: 1px solid white;
}
.font22 {
  font-size: 22px;
}

.foo-bottom {
  background: black;
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  color: rgb(187, 151, 105);
}

.foo_combo {
  width: 300px;
  justify-content: center;
}

.foo_link {
  width: auto;
  float: left;
  justify-content: center;
  height: auto;
  background: none;
  padding: 0px 10px 0px 10px;
}

.foo_link a {
  color: rgb(145, 107, 4);
}
.foo_link :hover {
  color: white;
}
</style>
