/* eslint-disable vue/multi-word-component-names */
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { Form, Field, ErrorMessage, configure } from 'vee-validate';
import store from './vuex/store'
import { createPinia } from 'pinia'
import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';
import vueAwesomeSidebar from 'vue-awesome-sidebar'
import 'vue-awesome-sidebar/dist/vue-awesome-sidebar.css'
import CKEditor from '@ckeditor/ckeditor5-vue';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import ScriptX from 'vue-scriptx'
import 'vue3-easy-data-table/dist/style.css';
import 'datatables.net-dt/css/jquery.dataTables.css';
import 'datatables.net';
import VueGtag from "vue-gtag";
import constants from './common/constants';



configure({
    validateOnInput: true,
});
const pinia = createPinia()

const app = createApp(App)
app
    .component('Form', Form)
    .component('Field', Field)
    .component('ErrorMessage', ErrorMessage)

app.use(pinia)
app.use(router)
app.use(store)
app.use(CKEditor)
app.use(VueSweetalert2)
app.use(vueAwesomeSidebar)
app.use(ScriptX)
app.use(ToastPlugin, {
    position: 'top',
    duration: 3000,
    dismissible: true
})
app.use(VueGtag, {
    config: { id: constants.measurementId }
});

app.mount('#app')