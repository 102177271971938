<template>
  <div
    v-if="!cookiesAccepted"
    class="fade show cookie-consent-banner"
    role="alert"
    style="line-height: 20px !important"
  >
    <div class="row d-flex justify-content-center">
      <div class="col-md-8 text-left">
        <span>
          We use cookies and similar technologies to enable services and
          functionality on our site and to understand your interaction with our
          service. <br />By clicking on accept, you agree to our use of such
          technologies for marketing and analytics.</span
        >
      </div>
      <div>
        <button
          @click="acceptAllCookies"
          class="btn btn-default"
          style="
            border-color: #6f3f13;
            color: black;
            border-radius: 20px;
          "
        >
          Accept
        </button>
        <button
          @click="cancelCookies"
          class="btn btn-default m-2"
          style="border-radius: 20px; border-color: #6f3f13;"
        >
          Decline
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cookiesAccepted: false,
    };
  },
  mounted() {
    if (localStorage.getItem("cookiesAccepted")) {
      this.cookiesAccepted = true;
    }
  },
  methods: {
    acceptAllCookies() {
      localStorage.setItem("cookiesAccepted", "true");
      this.cookiesAccepted = true;
    },
    cancelCookies() {
      this.cookiesAccepted = true;
    },
  },
};
</script>

<style>
.cookie-consent-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  background-color: #e1c37e;
  color: black;
  border-top: 1px solid #ddd;
  text-align: center;
  z-index: 999;
}
</style>
