<template>
  <div
    class="services-area carousel-shadow half-bg inc-thumb default-padding bg-gray"
  >
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-md-offset-2">
          <div class="site-heading text-center">
            <h2>Special PROMO</h2>
            <span class="devider"></span>
            <br />
            <span>
              <h3>
                “Get special offers for your happy moments with your beloved
                family or closest people only at Taman Dayu."
              </h3>
            </span>
          </div>
        </div>
      </div>

      <!-- <div class="row">
        <div
          class="col-md-4 single-item services-items"
          v-for="(item, index) in promotions"
          :key="index"
        >
          <div class="item">
            <div class="thumb">
              <router-link :to="`/promotions/${item.path}`">
                <img style="height: 400px" :src="item.thumbnail" alt="Thumb"
              /></router-link>
            </div>
            <div class="info text-center">
              <h5>
                <router-link :to="`/promotions/${item.path}`">{{
                  item.title
                }}</router-link>
              </h5>
            </div>
          </div>
        </div>
      </div> -->

      <BannerHorizontal :items="promotions" />
      <div class="row mt-3">
        <div class="col-12 d-flex justify-content-center">
          <router-link
            :to="`/category/promotions`"
            class="btn btn-theme border btn-md"
            style="background-color: black; color: white !important"
          >
            See More Promotions
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BannerHorizontal from "../components/VueHorizontal/BannerHorizontal.vue";
import { getListPost } from "../../common/datasource";
export default {
  components: { BannerHorizontal },
  data() {
    return {
      promotions: [
        {
          image:
            "https://tamandayu.magnamediadesign.com/assets/img/promotion/thumb-cookingclass_skyterrace.jpg",
          title: "Fun Cooking Class",
          by: "Sky Terrace",
          url: "cooking-class",
        },
        {
          image:
            "https://tamandayu.magnamediadesign.com/assets/img/promotion/thumb-ramadhankareem_hotel.jpg",
          title: "Ramadhan Kareem Package",
          by: "Taman Dayu Hotel",
          url: "ramadhan-hotel-promotion",
        },
        {
          image:
            "https://tamandayu.magnamediadesign.com/assets/img/promotion/thumb-shabushabu_skyterrace.jpg",
          title: "Shabu Shabu Package",
          by: "Sky Terrace",
          url: "shabu-shabu-package",
        },
      ],
    };
  },

  mounted() {
    this.getData();
  },

  methods: {
    async getData() {
      const response = await getListPost(2, 9);
      this.promotions = response;
    },
  },
};
</script>
