import { createRouter, createWebHistory } from 'vue-router'
import Home from './web/template1/home_page.vue'
import IndexTemplate1 from './web/template1/index_template.vue'
import LoginPage from './admin/auth/login_page.vue'
import CategoriesPage from './admin/category/category_page.vue'
import UserPage from './admin/user/user_page.vue'
import DashboardPage from './admin/dashboard_page.vue'
import ConfigurationPage from './admin/configuration/configuration_page.vue'
import PostList from './admin/post/post_page.vue'
import IndexPage from './admin/index_admin.vue'
import store from './vuex/store'
import jwtDecode from 'jwt-decode'
import PostPage from './web/template1/post_page.vue'
import CategoryPage from './web/template1/category_page.vue'
import CreatePostPage from './admin/createPost/create_post_page.vue'
import { event, pageview } from 'vue-gtag'
import CatalogsPage from './web/template1/catalogs_page.vue'
import SearchComponent from './web/template1/search_page.vue'
import NotFoundPage from './web/template1/not_found_page.vue'
import UnderConstruction from './web/under-construction.vue'

const routes = [
  {
    path: '/',
    component: IndexTemplate1,
    children: [
      { path: '', component: Home },
      { path: ':category/:path', component: PostPage },
      { path: 'category/:category', component: CategoryPage },
      { path: 'catalogs', component: CatalogsPage },
      { path: 'search/:searchValue', component: SearchComponent },
      { path: 'under-construction', component: UnderConstruction },
    ],
  },
  { path: '/login', component: LoginPage, name: 'LoginPage' },
  {
    path: '/admin',
    component: IndexPage,
    children: [
      { path: 'categories', component: CategoriesPage },
      { path: 'dashboard', component: DashboardPage },
      { path: 'users', component: UserPage },
      { path: 'configurations', component: ConfigurationPage },
      { path: 'posts', component: PostList },
      { path: 'posts/create', component: CreatePostPage },
      { path: 'posts/:id/detail', component: CreatePostPage },
    ],
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/:pathMatch(.*)',
    component: NotFoundPage,
  },
]

const router = createRouter({
  history: createWebHistory(''),
  mode: 'history',
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  }
})

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token')
  const user = token ? jwtDecode(token) : null
  const isCookieAccepted = localStorage.getItem("cookiesAccepted");

  if (isCookieAccepted) {
    event('page', { method: to.path })
    pageview({ page_path: to.path })
  }

  if (to.matched.some((route) => route.meta.requiresAuth)) {
    if (token && user) {
      store.commit('setToken', token)
      store.commit('setUser', user)
      next()
    } else {
      next('/login')
    }
  } else {
    next()
  }
})

export default router
