<template>
  <link
    href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css"
    rel="stylesheet"
  />
  <div>
    <body>
      <header-component />
      <sidebar-component />

      <main id="main" class="main">
        <router-view />
      </main>
    </body>
  </div>
</template>

<style scoped>
@import "../assets/style.css";
</style>

<script>
import HeaderComponent from "./header_component.vue";
import SidebarComponent from "./sidebar_component.vue";
export default {
  components: { HeaderComponent, SidebarComponent },
};
</script>
