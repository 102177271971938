import constants from "../common/constants";
import httpClient from './httpClient';

const getConfig = (isFormData = false) => {
    return {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            ...(isFormData && { "Content-Type": "multipart/form-data" })
        }
    }
};

export const getListCategory = async (fromAdmin = 0) => {
    const response = await httpClient.get(`${constants.categories}?fromAdmin=${fromAdmin}`, getConfig());
    return response.data.data;
}

export const createCategory = async (payload) => {
    return await httpClient.post(constants.categories, payload, getConfig());
}

export const patchCategory = async (payload) => {
    return await httpClient.patch(`${constants.categories}/${payload.id}`, payload, getConfig());
}

export const getListUser = async () => {
    const response = await httpClient.get(constants.users, getConfig());
    return response.data.data;
}

export const createUser = async (payload) => {
    return await httpClient.post(constants.users, payload, getConfig());
}

export const getListConfiguration = async () => {
    const response = await httpClient.get(constants.configurations, getConfig());
    return response.data.data;
}

export const patchBulkConfiguration = async (payload) => {
    const response = await httpClient.patch(constants.configurationsBulk, payload, getConfig());
    return response.data.data;
}

export const getListPost = async (category, limit, searchValue) => {
    let filterCategory = '';
    let filterLimit = '';
    let filterSearch = '';
    if (category) {
        filterCategory = `categoryId=${category}`;
    }
    if (limit) {
        filterLimit = `limit=${limit}`;
    }
    if (searchValue) {
        filterSearch = `searchValue=${searchValue}`;
    }
    const response = await httpClient.get(`${constants.posts}?${filterCategory}&${filterLimit}&${filterSearch}`, getConfig());
    return response.data.data;
}

export const getPostByPath = async (path) => {
    const response = await httpClient.get(`${constants.posts}/${path}`);
    return response.data.data;
}

export const getPostById = async (id) => {
    const response = await httpClient.get(`${constants.posts}/id/${id}`);
    return response.data.data;
}

export const updatePost = async (payload) => {
    await httpClient.patch(`${constants.posts}/${payload.id}`, payload, getConfig());
}

export const createPost = async (payload) => {
    const response = await httpClient.post(constants.posts, payload, getConfig());
    return response.data.data;
}

export const deletePost = async (id) => {
    return await httpClient.delete(`${constants.posts}/${id}`, getConfig());
}

export const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    return await httpClient.post(`${constants.upload}`, formData, getConfig(true));
}

export const getLatestPromo = async () => {
    const response = await httpClient.get(`${constants.latestPromo}`);
    return response.data.data;
}

export const deleteUser = async (id) => {
    return await httpClient.delete(`${constants.users}/${id}`, getConfig());
}

export const patchUser = async (id, payload) => {
    return await httpClient.patch(`${constants.users}/${id}`, payload, getConfig());
}

export const getListCatalogs = async () => {
    const response = await httpClient.get(constants.catalogs);
    return response.data.data;
}

export const getPostsFromAdmin = async () => {
    const response = await httpClient.get(constants.postsAdmin, getConfig());
    return response.data.data;
}

export const getCategoriesFromAdmin = async () => {
    const response = await httpClient.get(constants.categoriesAdmin, getConfig());
    return response.data.data;
}