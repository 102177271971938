<template>
  <div>
    <!-- <div class="dialog" v-if="dialog && promo" ref="dialogPopup">
      <div class="dialog-content">
        <span
          @click="closeDialog"
          href="#"
          class="close-side close-icon"
          style="color: black; font-size: 24px; cursor: pointer"
          ><i class="fa fa-times-circle"></i
        ></span>
        <img :src="promo.thumbnail" />
      </div>
    </div> -->
    <div class="dialog" ref="dialogPopup" v-if="dialog">
      <div class="dialog-content">
        <span
          @click="closeDialog"
          href="#"
          class="close-side close-icon"
          style="color: black; font-size: 24px; cursor: pointer"
          ><i class="fa fa-times-circle"></i
        ></span>
        <img :src="'https://www.tamandayu.com/_assets/img/onlinebooking.jpg'" />
        <div style="background-color: #a59365; padding: 10px; text-align: center; padding-bottom: 15px">
          <p class="text-white">
            Get discounted price worth Rp100.000 with Promo Code <b>CERIA</b>
          </p>
          <a
            href="https://staahmax.staah.net/be/indexpackdetail?propertyId=MzkyMg==&individual=true"
            target="_blank"
            class="btn btn-sm btn-book"
            >Book Hotel Now</a
          >
        </div>
      </div>
    </div>

    <div class="video-container">
      <video
        autoplay="autoplay"
        playsinline
        loop
        muted
        style="object-fit: contain; width: 100%"
      >
        <source
          src="https://www.tamandayu.com/lifestyle_td2.mp4"
          type="video/mp4"
        />
      </video>
    </div>
    <promo-component ref="promoSection" />
    <our-story />
    <our-gallery />
    <latest-news />
  </div>
</template>

<script>
import PromoComponent from "./promo_component.vue";
import OurStory from "./our_story.vue";
import OurGallery from "./our_gallery.vue";
import LatestNews from "./latest_news.vue";
import { getLatestPromo } from "@/common/datasource";

export default {
  name: "HomePage1",
  components: {
    PromoComponent,
    OurStory,
    OurGallery,
    LatestNews,
  },
  data() {
    return {
      dialog: true,
      promo: null,
    };
  },
  async mounted() {
    await this.getLatestPromo();
    this.toggleScroll();
  },
  methods: {
    async getLatestPromo() {
      this.promo = await getLatestPromo();
    },

    closeDialog() {
      this.dialog = false;
      this.toggleScroll();
    },

    toggleScroll() {
      if (this.dialog) {
        document.body.classList.add("disable-scroll");
      } else {
        document.body.classList.remove("disable-scroll");
      }
    },
  },
};
</script>

<style>
.dialog {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  border: 1px solid #999;
  z-index: 999;
}
.dialog-content {
  width: 320px;
  height: auto;
  margin: 0 auto;
  /* padding-top: 10px; */
  margin-top: 30px;
  background: white;
  border-radius: 10px;
  padding: 5px;
}
.dialog-content img {
  width: 100%;
  height: auto;
}
.close-icon {
  float: right;
}
.disable-scroll {
  overflow: hidden;
}
.video-container {
  position: relative;
}

.overlay-button {
  position: absolute;
  top: 30px;
  right: 10px;
  z-index: 1;
  color: black !important;
}

.btn-book {
  padding: 16px 20px;
  background-color: #d8802d;
  color: #fff;
  font-weight: bold;
  font-size: 1.3em;
  line-height: 1.3em;
  display: inline-block;
  padding: 8px 10px !important;
}
</style>
