import { createStore } from 'vuex'
import axios from 'axios'
import jwtDecode from 'jwt-decode'
import constants from "../common/constants";

const store = createStore({
  state: {
    token: null,
    user: null,
  },
  mutations: {
    setToken(state, token) {
      state.token = token
    },
    setUser(state, user) {
      state.user = user
    },
  },
  actions: {
    async login({ commit }, credentials) {
      try {  
        const response = await axios.post(constants.login, credentials);

        const token = response.data.token
        const user = jwtDecode(token)
        commit('setToken', token)
        commit('setUser', user)
        localStorage.setItem('token', token)

        return token;
      } catch (error) {
        console.log(error)
      }
    },
    async logout({ commit }) {
      commit('setToken', null)
      commit('setUser', null)
      localStorage.removeItem('token')
    },
  },
})

export default store
